import React from 'react'
import './footerStyles.scss'
import romeImage from '../images/parish_rome_image-01.png'
import deLogo from '../images/deLogo-01.png'
import ScrollToTop from "react-scroll-to-top";
import {MdOutlineCopyright} from 'react-icons/md'

const Footer = () => {
  const dateNow = new Date();
  const yearNow = dateNow.getFullYear();

  return (
    <div className='footer-cover'>
      <ScrollToTop smooth top="800" color='#4f2500'/>
      <div className='footer-image'>
        <img src={romeImage}/>
      </div>
      <div>
      <div className='details-cover'>
        <div className='details'>
          <h4>Catholic Archdiocese Of Harare</h4>
            <span>
              Mutemwa Leprosy Catholic and Care Centre</span>
              <span><MdOutlineCopyright style = {{color: '#fff', marginRight: '.1rem'}}/> 
              1937 - {yearNow}. All Rights Reserved.
            </span>
        </div>
            
      </div>
      </div>
      <div className='developer'>
        <p>Powered by <a href='https://developessentials.co.zw'>DevelopEssentialsInc.</a></p>
      </div>
    </div>
  )
}

export default Footer