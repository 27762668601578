import React from 'react'
import './donationStyles.scss'
import Ecocash from '../images/EcoCash_IMTC.jpg'
import AFCBank from '../images/AFC_LOGO.png'

const Donation = () => {
  return (

    <div className='donation-cover'>
        <div className='image-top'>
            <h4>Donations for Mutemwa</h4>
        </div>
        <div className='cover'>
            <div className='drop'  style={{"--clr":"#4f2500"}}>
                
                <div className='content' >
                    <h4>2 Corinthians 9:6-8</h4>
                    <p>Each of you should give what you have decided in your heart to give, not reluctantly or under compulsion, for God loves a cheerful giver. And God is able to bless you abundantly, so that in all things at all times, having all that you need, you will abound in every good work.</p>
                    
                </div>
            </div>
            <div className='drop' style={{"--clr":"#0E39EA"}}>
                <div className='content' >
                    <h4>ECOCASH</h4>
                    <div className='logo'>
                        <img src={Ecocash}/>
                    </div>
                    <p>*151*2*2*55461*AMOUNT#</p>
                    
                </div>
            </div>
            
            <div className='drop' style={{"--clr":"#33801D"}}>
                <div className='content' >
                    <h4>NOSTRO</h4>
                    <div className='logo'>
                        <img src={AFCBank}/>
                    </div>
                    <p>Bank:<br/>AGRIBANK</p>
                    <p>Account Name: <br/>Mutemwa Leprosy Centre</p>
                    <p>Account Number: <br/>100010474568</p>
                    
                </div>
            </div>

            <div className='drop' style={{"--clr":"#33801D"}}>
                <div className='content' >
                    <h4>RTGS</h4>
                    <div className='logo'>
                        <img src={AFCBank}/>
                    </div>
                    <p>Bank: <br/>AGRIBANK</p>
                    <p>Account Name: <br/>Mutemwa Leprosy Centre</p>
                    <p>Account Number: <br/>100001102936</p>
                </div>
            </div>
            
        </div>
        <div className='donors-cover'>
            <div className='donors-names'>
                <h4>We always give THANKS to our donors <br/><span></span></h4>
            </div>
        </div>
    </div>

  )
}

export default Donation