import slide4 from '../images/flyer09Nov2024.jpg';
const announcements = [
    {
        id:1,
        title:"To All Parishioners: REF: Fundraising Dinner Dance",
        date:"09 Novemver 2024",
        from:"Chaplin",
        message:`John Randal Bradburne Anniversary Fundraising Dinner Dance` 
    },
    {
        id:2,
        title:"DISCLAIMER: BEWARE OF FAKE MUTEMWA BANK ACCOUNTS",
        date:"22 May 2023 ",
        from:"Management",
        message:"Beware of fake Mutemwa bank accounts or people who claim to be from Mutemwa Leprosy Centre and Care Centre organisation.",
        image:"available"
    },

]

export default announcements;