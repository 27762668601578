import React from 'react'
import './homeStyles.scss'
//import {Canvas} from '@react-three/fiber'
//import {OrbitControls, Preload, useGLTF} from '@react-three/drei'
import {Link,NavLink} from 'react-router-dom'
import { Parallax } from "react-parallax";
import Bradburne from '../images/johnbradburn.jpg';
import MountainCross from '../images/mountainCross-01.png';
import JohnNAudrey from '../images/John-and-Sister-Audrey.jpg';
import JohnAsArmyOfficer from '../images/John-Army-Officer.jpg';
import JohnPlaying from '../images/John-Playing-Recorder.jpg';
import JohnNSisters from '../images/John-With-Sisters.webp';
import JohnGarment from '../images/JohnGarment.jpg';
import JohnAtMountain from '../images/John-At-Mountain.webp';
import bradburneNfriend from '../images/bradburneNfriend.webp';
import JohnBradburne2 from '../images/JohnBradburne2.jpg';
import hut from '../images/hut5.jpg';
import Poster from '../images/poster.jpg';
import Poster3 from '../images/poster3.jpg';
import JohnBradburne from '../images/JohnBradburne.jpg';
import JohnAtMutemwa from '../images/jb-at-mutemwa_orig.jpg';
import Scotchcart from '../images/scotchcart.jpg';
import Scotchcart2 from '../images/scotchcart2.jpg';
import slide1 from '../images/bradburne-slide1-01.png';
import slide2 from '../images/mountain-slide2-01.png';
import slide3 from '../images/rosary-slide3-01.png';
import slide4 from '../images/flyer09Nov2024.jpg';
import slide5 from '../images/sponsores-slide5-01.png';
import Binoculars from '../images/binoculars-4-svgrepo-com.svg';
import Mission from '../images/business-management-mission-svgrepo-com.svg';
import Objectives from '../images/goal-idea-objective-svgrepo-com.svg';
import announcements from './announcements';
import Bounce from 'react-reveal/Bounce';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const Home = () => {


  return (
    <div className='home-cover'>
        <div className='banner1'>
            <img src={slide1}/>
            <div className='text-description text-box1'>
                <h1>Home of John Bradburne</h1>
                <span></span>
                <p>Welcome to Mutemwa Leprosy Catholic & Care Centre</p>
            </div>
        </div>
        <div className='banner2'>
            <img src={slide2}/>
            <div className='text-description text-box2'>
                <h1>Prayer Mountain</h1>
                <span></span>
                <p>Prayer Mountain</p>
            </div>
        </div>
        <div className='banner3'>
            <img src={slide3}/>
            <div className='text-description text-box3'>
                <h1>Catholic Church</h1>
                <span></span>
                <p>Archdiocese of Harare</p>
            </div>
        </div>

        <div className='banner4'>
            <img src={slide4}/>
            <div className='text-description text-box4'>
                <h1>John Randal Bradburne <br/>Anniversary</h1>
                <span></span>
                <p>Fundraising Dinner Dance</p>
                {/* <button className='btn-register'><a href="https://bit.ly/MemorialRaceJohnRBradburne">Register Now</a></button> */}
            </div>
        </div>

        <div className='banner5'>
            <img src={slide5}/>
            <div className='text-description text-box5'>
                <h1>Our Sponsores</h1>
                <span></span>
                <p>We are very proud of our regular sponsores & partners.</p>
            </div>
        </div>

        <div className='landing'>

            <div className='title'>
            <span></span>
                <h4>Vision | Mission | Objectives</h4>
                <span></span>
            </div>

            {/* <div className='welcome'>
                <ul className='option'>
                    <li><span>Mutemwa Leprosy Catholic And Care Centre</span></li>
                    <li><span>Mutemwa Leprosy Settlement was established in 1937 as a Leprosium.</span></li>
                    <li><span>In 1969, John Randal Bradburne (14 June 1921 - 05 September 1979) became the warden of the settlement.</span></li>
                    <li><span>He cares for the sick in the house.</span></li>
                    <li><span>Currently, Mutemwa Leprosy and Care Centre hold an average of 32 residents.</span></li>
                    <li><span>Mutemwa Settlement is in Mutoko a small town in Mashonaland East Province of Zimbabwe. It lies 143km from Harare.</span></li>
                </ul>       
            </div>    */}

            {/* <Parallax strength={600} bgImage={Bradburne}>
                <div className='d-design'>
                
                    <Bounce right>
                        <div className='invitation-cover'>
                            
                            <div className='invitation'>
                                <h4>Public Notifications</h4>
                                {announcements.map((item)=>(
                                    <div className='message'>
                                        <p>Date: {item.date}</p>
                                        <p>{item.title}</p>
                                        <p>From: {item.from}</p>
                                    </div>
                                ))}
                            </div>
                            
                            
                            <button className='btn-viewMessage' ><NavLink to='/notifications'>Open All Notifications</NavLink></button>
                        </div>
                    </Bounce>
                    <Zoom top delay={200} duration={200}>
                        <div className='brad-background'>
                                <div className='drop' style={{"--clr":"#4f2500"}}>
                                    <div className='content' >
                                        <h4>Mutemwa Leprosy Catholic And Care Centre</h4>
                                        <p><span></span></p>
                                    </div>
                                </div>
                            </div>
                    </Zoom>
                            
                        
                </div>
            </Parallax> */}
            
            <div bgImage={MountainCross}>
                <div className='vision'>
                
                    <div className='drop' style={{"--clr":"#4f2500"}} data-aos="fade-down-right">
                        <div className='content' >
                            <div className='svg'><img src={Binoculars} alt="vison"/></div>
                            <h4>Vision Statement</h4>
                            <p>To be a self-sustainable organization that provides a homely environment to Post Leprosy Residents, the Physically Challenged, the Old aged and the Destitute.</p>
                        </div>
                    </div>
                

                    <div className='drop' style={{"--clr":"#4f2500"}} data-aos="flip-left">
                        <div className='content' >
                            <div className='svg'><img src={Mission} alt="mission"/></div>
                            <h4>Mission Statement</h4>
                            <p>To provide Medical, Psycho– social, Mental, Spiritual Care, and Rehabilitation services to the Physically Challenged Persons.</p>
                        </div>
                    </div>
                

                
                    <div className='drop' style={{"--clr":"#4f2500"}} data-aos="fade-down-left">
                        <div className='content' >
                            <div className='svg'><img src={Objectives} alt="objectives"/></div>
                            <h4>Our Objectives</h4>
                                <p>•	Provision of a loving homely environment to Post– Leprosy Residents, the destitute and the physically challenged persons. <br/><br/>
                                •	Provision of Rehabilitation Services to those in need. <br/><br/>
                                •	Provision of Spiritual Care through Christian Based Practices while respecting other Faiths. <br/><br/>
                                •	To enhance Self Sustenance through income generating projects so as to provide a positive response to the economic and social needs of the Residents.
                                </p>
                        </div>
                    </div>
                
                </div>
            </div>



            <div className='bradburne-cover'>
                    <div className='bradburne'>
                        <h5>John Randal Bradburne (14 June 1921 - 05 September 1979 (aged 58))</h5>
                        
                            <div className='john' data-aos="fade-up" data-aos-anchor-placement="top-center">
                                <div className='my-div'>
                                    <h4>1921 - 1939</h4>
                                    <div className='img-inside'>
                                        <img src={JohnNAudrey} alt="Bradburne"/>
                                        <img src={JohnPlaying} alt="Bradburne"/>
                                    </div>
                                
                                <p>Born on the 14th of June 1921 and he did his education at Gresham's a private school in Holt, Norfolk, England from 1934 to 1939.<br/><br/> Bradburne was a member of the school's Officer's Training Corps is a youth organisation in the United Kingdom, sponsored by Ministry of Defence (MOD).<br/><br/> He was planning to continue his studies at a university but at the outset of the Second World War he volunteered for the British Indian Amry, commonly referred as Indian Army, was the main military of the British Raj before its dissolution in 1947.<br/><br/> He joined that army because his mother who was connected and she had been born in Lucknow, India.</p></div>
                            </div>
                        
                        
                        
                            <div className='john' data-aos="fade-up"
     data-aos-anchor-placement="top-center">
                                <div className='my-div'><h4>1940 - 1946</h4>
                                <div className='img-inside'>
                                        <img src={JohnAsArmyOfficer} alt="Bradburne"/>
                                    </div>
                                <p>In December 1940 Bradburne was commissioned in the Indian Army.<br/><br/> He was assigned to the 9th Gurkha Riflesof the Indian Army and soon posted with them to British Malaya to face the invasion of the Imperial Japanese Army.<br/><br/> After the fall of Singapore in February 1942, Bradburne spent a month in the jungle. <br/><br/>With another Gurkha officer, he tried to sail a sampan to Sumatra but they were shipwrecked.<br/><br/> A second attempt was successful, and Bradburne was rescued by a Royal Navy destroyer and returned to Dehra Dun in India.<br/><br/> He then saw active service with Orde Charles Wingate's Chindits in Burma Campaign.</p></div>
                            </div>
                    
                       
                       
                        <div className='john' data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <div className='my-div'>
                            <h4>1946 - 1955</h4>
                            <div className='img-inside'>
                                    <img src={JohnNSisters} alt="Bradburne"/>
                                </div>
                            <p>Bradburne relinquished his commission in March 1946 on account of ill health.<br/><br/> He had a religious experience in Malaya, and his faith became the dominant impulse in his life.<br/><br/> When he returned to England after the war, he stayed with the Benedictines (Order of Saint Benedict) of Buckfast Abbey, where he became a Roman Catholic in 1947.<br/><br/> He wanted to be a Benedictine monk but the Order would not accept him because he had not been in the Church for two years.<br/><br/> After a while, he felt a strong urge to travel.
                            For the next sixteen years, Bradburne wandered through England, France, Italy, Greece and the Middle East with only a Gladstone bag (A small suitcase built over a rigid frame which can separate into two equal sections).<br/><br/> In England, he stayed with the Carthusians (Order of Carthusians) for seven months. In Israel, he joined the small Order of Our Lady of Mount Sion, and went as a novice (a period of training and preparation that a member of religious order undergoes prior to taking vows in order to discern whether they are called to vowed religious life.) to Leuven (Louvain), Belgium, for a year, where he met Géza Vermes ( was a British academic, Biblical scholar, and Judaist of Jewish-Hungarian descent—one who also served as a Roman Catholic priest in his youth) who became a noted scholar.<br/><br/> After that, he walked to Rome and lived for a year in the organ loft of the small church in a mountain village, playing the organ.<br/><br/> He tried to live as a hermit on Dartmoor (an upland area in southern Devon, England), then went to the Benedictine Prinknash Abbey (is a Roman Catholic monastery in the Vale of Gloucester in the Diocese of Clifton, near the village of Cranham in England), before joining the choir of Westminster Cathedral (is the mother church of the Catholic Church in England and Wales.<br/><br/> It is the largest Catholic church in the UK and the seat of the Archbishop of Westminster.) as a sacristan (an officer charged with care of the sacristy). Cardinal Godfrey (an English Cardinal of the Roman Catholic Church. <br/><br/>He served as Archbishop of Westminster) asked him to be the caretaker of his country house, Hare Street House, in Hertfordshire.
                            </p>
                            </div>
                            
                        </div>
                        
                        <div className='john' data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <div className='my-div'><h4>1956 – 1978</h4>
                            <div className='img-inside'>
                                    <img src={JohnGarment} alt="Bradburne"/>
                                    <img src={JohnAtMountain} alt="Bradburne"/>
                                </div>
                            <p>On Good Friday 1956, Bradburne joined the Secular Franciscan Order but remained a layman.
                            Bradburne's wanderlust was coming to an end in 1962, when he wrote to a Jesuit friend in Rhodesia (now Zimbabwe), Fr John Dove SJ.<br/><br/> He asked, "Is there a cave in Africa where I can pray?" The answer was the invitation to come to Rhodesia and be a missionary helper.<br/><br/><div className='img-inside'>
                                    <img src={JohnBradburne} alt="Bradburne"/>
                                    <img src={JohnAtMutemwa} alt="Bradburne"/>
                                    <img src={Scotchcart} alt="Bradburne"/>
                                    <img src={Scotchcart2} alt="Bradburne"/>
                                </div> This is where in 1969, Bradburne found Mutemwa Leprosy Settlement near Mutoko, 143 kilometres (89 miles) northeast of Salisbury (now Harare).<br/><br/> It was a cut-off community of leprosy patients abandoned by others. <br/><br/>Here Bradburne stayed with these patients until his murder in 1979.<br/><br/> He cared for them as their warden but fell out with the Leprosy Association and was expelled from the colony. <br/><br/>John carried food in a scotch cart from Mutoko centre to Mutemwa for his patients.<br/><br/><div className='img-inside'>
                                    <img src={JohnBradburne2} alt="Bradburne"/>
                                    <img src={hut} alt="Bradburne"/>
                                    <img src={bradburneNfriend} alt="Bradburne"/>
                                </div> He stayed in a tin hut, just outside the perimeter fence, for the last six years of his life but continued to minister to the lepers.<br/><br/>
                            After his arrival to Africa, Bradburne told a Franciscan priest that he had three wishes: to help the victims of leprosy, to die a martyr (In Christianity, a martyr is a person considered to have died because of their testimony for Jesus or faith in Jesus.), and to be buried in the Franciscan habit.
                            </p></div>
                        </div>
                        
                        <div className='john' data-aos="fade-up" data-aos-anchor-placement="top-center">
                            <div className='my-div'><h4>1979</h4>
                                <div className='img-inside'>
                                    <img src={MountainCross} alt="Bradburne"/>
                                    <img src={Poster} alt="Bradburne"/>
                                    <img src={Poster3} alt="Bradburne"/>
                                </div>
                            <p>By July 1979, the Rhodesian Bush War (also called the Second Chimurenga as well as the Zimbabwe War of Independence), then in its 15th and last year, was approaching Mutemwa.<br/><br/> His friends urged Bradburne to leave but he insisted that he should stay with the lepers. <br/><br/>"You're not safe here, John. You are going to be killed". The warning had been delivered by Catholic priest Fidelis Mukonori, his friend and driver, as they wound their way through the dusty roads to Mutemwa's leper colony.<br/><br/> On 2 September 1979, terrorists of the Zimbabwe African National Liberation Army abducted him from his small hut in Mutemwa, north-east Zimbabwe, tortured and shot in the back on the 5th of September at the age of 58 - accused of being an informer.<br/><br/> He was buried in a Franciscan habit, according to his wishes, at the Chishawasha Mission Cemetery, about 18 kilometres (11 miles) northeast of Salisbury (now Harare).</p></div>
                        </div>
                    </div>
                    
            </div>

        </div>   
    </div>
  )
}

export default Home