import React from 'react'
import {Link} from 'react-router-dom'
import './pageNotFound.scss'
import errorimage from '../images/404-not-found-error-sign.jpg'

const PageNotFound = () => {
  return (
    <div className='notFound'>
        <img src={errorimage} height='200' width='200'/>
        <div className='text'>
            <h2>Oops! 404 Error</h2>
            <h4>Page not found</h4>
            <Link to='/'>Back to homepage...</Link>
        </div>
            
    </div>
  )
}

export default PageNotFound