import React, { useState } from 'react'
import {Route, Routes} from 'react-router-dom'
import ContactsBar from './components/ContactsBar';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import Aboutus from './components/Aboutus';
import VisitMutemwa from './components/VisitMutemwa';
import Donation from './components/Donation';
import Projects from './components/Projects';
import Contacts from './components/Contacts';
import PageNotFound from './components/PageNotFound';
import Notifications from './components/Notifications';

function App() {

  const [loadSpinner,setLoadSpinner] =useState(true);
  const [selected,setSelected] = useState(null);

  const toggle = (i) =>{
    if(selected == i) {
      return setSelected(null)
    }
    setSelected(i)
  }

  return (
    <React.Fragment>
      <ContactsBar/>
      <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about-mutemwa' element={<Aboutus/>}/>
        <Route path='/visit-mutemwa' element={<VisitMutemwa/>}/>
        <Route path='/donate-now' element={<Donation/>}/>
        <Route path='/our-projects' element={<Projects/>}/>
        <Route path='/notifications' element={<Notifications toggle={toggle} selected={selected}/>}/>
        <Route path='*' element={<PageNotFound/>}/>
        {/* <Route path='/contact-us' element={<Contacts/>}/> */}
      </Routes>
      <Footer/>
    </React.Fragment>
  );
}

export default App;
