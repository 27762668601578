import React from 'react'
import {Link,NavLink} from 'react-router-dom'
import './projectStyles.scss'
import Building1 from '../images/building.jpg'
import Building2 from '../images/building2.jpg'
import Building3 from '../images/building3.jpg'
import Garden1 from '../images/garden1.jpg'
import Garden2 from '../images/garden2.jpg'
import Garden3 from '../images/garden3.jpg'
import Derere from '../images/derere.jpg'
import Cabbages from '../images/cabbages.jpg'
import Cabbages2 from '../images/cabbages2.jpg'
import Maize from '../images/maize2.jpg'
import Onions from '../images/onions.jpg'
import Okra from '../images/okra.jpg'
import Onions2 from '../images/onions2.jpg'
import Tomatoes from '../images/tomatoes.jpg'
import Poultry from '../images/chickenLayers.jpg'
import Poultry2 from '../images/chickenLayers2.jpg'
import Pigs1 from '../images/pigs4.jpg'
import Pigs from '../images/pigs.jpg'
import Pigs2 from '../images/pigs2.jpg'
import Pigs3 from '../images/pigs3.jpg'


const Projects = () => {
  return (
    <div className='projects-cover'>
        <div className='image-top-projects'>
            <h4>Sustaining Projects</h4>
        </div>

        <div className='first-projects'>

            <div className='drop' style={{"--clr":"#e77a1b"}}>
                <div className='content' >
                    <h4>Poultry</h4>
                    <p>Layers for eggs nutritional support and we sell excess for running the project..</p>
                    <NavLink className='navlink' to='/donate-now'>Donate Now</NavLink>
                </div>
            </div>
            <div className='drop'  style={{"--clr":"#C5CA15"}}>
                
                <div className='content' >
                    <h4>Piggery</h4>
                    <p>For nutritional support and we sell excess to keep running the project.</p>
                    <NavLink className='navlink' to='/donate-now'>Donate Now</NavLink>
                </div>
            </div>
            <div className='drop' style={{"--clr":"#FF009B"}}>
                <div className='content' >
                    <h4>Cattle</h4>
                    <p>Beef and milk for residents as well as manure for the garden.</p>
                    <NavLink className='navlink' to='/donate-now'>Donate Now</NavLink>
                </div>
            </div>
            
            <div className='row-images'>
                <div className='cards'>
                    <div className='first'>
                        <img src={Pigs2} alt="pigs"/>
                    </div>
                    <div className='first'>
                        <img src={Building1} alt="building"/>
                    </div>
                    <div className='first'>
                        <img src={Poultry} alt="poultry"/>
                    </div>
                    <div className='first'>
                        <img src={Building2} alt="building"/>
                    </div>
                    <div className='first'>
                        <img src={Poultry2} alt="poultry"/>
                    </div>
                    <div className='first'>
                        <img src={Building3} alt="building"/>
                    </div>
                    <div className='first'>
                        <img src={Pigs1} alt="pigs"/>
                    </div>
                </div>
            </div>
        </div>

        <div className='second-projects'>

            {/* <div className='drop' style={{"--clr":"#1A23E9"}}>
                <div className='content' >
                    <h4>Fish Farming</h4>
                    <p>Fish farming or pisciculture involves commercial breeding of fish, most often for food. Your support is very important to be able to construct ponds and maintenance.</p>
                    <NavLink className='navlink' to='/donate-now'>Donate Now</NavLink>
                </div>
            </div> */}
            <div className='drop'  style={{"--clr":"#FF0000"}}>
                <div className='content'>
                    <h4>Horticulture</h4>
                    <p>For nutritional support all basic vegetables.</p>
                    <NavLink className='navlink' to='/donate-now'>Donate Now</NavLink>
                </div>
            </div>
            <div className='drop' style={{"--clr":"#14CD05"}}>
                <div className='content'>
                    <h4>Farming</h4>
                    <p>Maize, groundnuts and sugar beans for nutritional support.</p>
                    <NavLink className='navlink' to='/donate-now'>Donate Now</NavLink>
                </div>
            </div>
            <div className='row-images'>
                <div className='cards'>
                    <div className='first'>
                        <img src={Garden1} alt="garden"/>
                    </div>
                    <div className='first'>
                        <img src={Garden2} alt="garden"/>
                    </div>
                    <div className='first'>
                        <img src={Garden3} alt="garden"/>
                    </div>
                    <div className='first'>
                        <img src={Cabbages} alt="cabbages"/>
                    </div>
                    <div className='first'>
                        <img src={Derere} alt="garden"/>
                    </div>
                    <div className='first'>
                        <img src={Maize} alt="maize"/>
                    </div>
                    <div className='first'>
                        <img src={Cabbages2} alt="cabbages"/>
                    </div>
                    <div className='first'>
                        <img src={Onions} alt="onions"/>
                    </div>
                    <div className='first'>
                        <img src={Onions2} alt="onions"/>
                    </div>
                    <div className='first'>
                        <img src={Okra} alt="okra"/>
                    </div>
                    <div className='first'>
                        <img src={Tomatoes} alt="tomatoes"/>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
  )
}

export default Projects