import React from 'react'
import './visitMutemwaStyles.scss'

const VisitMutemwa = () => {
  return (
    <div className='visit-mutemwa-cover'>
        <div className='image-visit-mutemwa'>
            <h4>Visit Mutemwa Today</h4>
        </div>

        <div className='visitor-cover'>

                <div className='drop'  style={{"--clr":"#fff"}}>
                
                <div className='content' >
                    <h4>Bookings</h4>
                    <p>Kindly make your booking with the administration office at least one week before the proposal date of visit.</p>
                </div></div>
            

            
                <div className='drop'  style={{"--clr":"#fff"}}>
                
                <div className='content' >
                    <h4>Contact numbers</h4>
                    <p>+263 713 922 311 / +263 773 440 730</p>
                    
                </div>
            </div>
            
            

                
            <div className='drop'  style={{"--clr":"#fff"}}>
                
                <div className='content' >
                <h4>Administration Fee</h4>
                    <p>With effect from 1 December 2022 we charge $1 per head - General maintenance and development</p>
                </div>
            </div>

            <div className='drop'  style={{"--clr":"#fff"}}>
                
                <div className='content' >
                    <h4>Parking fee for security</h4>
                    <p>Small cars - $2<br/>Buses/Coaches - $5</p>
                    
                </div>
            </div>

            <div className='drop'  style={{"--clr":"#fff"}}>
                
                <div className='content' >
                <h4>Our weekly masses</h4>
                    <p>Mondays - no mass <br/>Tuesday to Friday - 7am<br/>Fridays and Saturdays - 10am<br/>(Weekend day masses to be discussed with the Chaplin upon booking.)<br/>Sunday - 7am</p>
                </div>
            </div>
                
            <div className='drop'  style={{"--clr":"#fff"}}>
                
                <div className='content' >
                    <h4>Places to visit.</h4>
                    <p>Bradburne's scotch cart, Mountain, Tin hut (for special petitions), and Post leprosy patients who lived with Jorn Bradburne.</p>
                    
                </div>
            </div>
 
        </div> 
    </div>
  )
}

export default VisitMutemwa