import React, {useState} from 'react'
import {Link,NavLink} from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './navbarStyles.scss'
import Logo from '../images/logo192.png'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const Navbar = () => {

    const[click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const [menu_class, setMenuClass] = useState("nav-menu hidden");
    const [isMenuClicked, setIsMenuClicked] = useState(true);

    const updateMenu=()=>{
        if(isMenuClicked){
            setMenuClass("nav-menu visible");
        }
        else{
            setMenuClass("nav-menu hidden")
        }
        setIsMenuClicked(!isMenuClicked);
    }
  return (
    <div className='header'>
            <div className='logo'><img src={Logo}/><h4>Mutemwa</h4></div>
            
            <ul className = {menu_class}>
                <li><span></span>
                    <NavLink  to='/'>Home</NavLink>
                </li>
                <li><span></span>
                    <NavLink  to='about-mutemwa'>About Mutemwa</NavLink>
                </li>
                <li><span></span>
                    <NavLink  to='/our-projects'>Projects</NavLink>
                </li>
                <li><span></span>
                    
                    <NavLink  to='/notifications'><NotificationsNoneIcon/>Notifications</NavLink>
                </li>
                <li><span></span>
                    <NavLink  to='/visit-mutemwa'>Visit Mutemwa</NavLink>
                </li>
                {/* <li><span></span>
                    <NavLink  to='/contact-us'>Contact us</NavLink>
                </li> */}
                <li><span></span>
                    <NavLink  to='/donate-now'>Donate Now</NavLink>
                </li>
            </ul>
                
            <div className='hamburger' onClick={()=>{updateMenu();handleClick()}} >
                {
                    click ? (<CloseIcon size={30} style={{color: '#fff'}} />) :
                    (<MenuIcon size={30} style={{color: '#fff'}}/>)
                }
            </div>
    </div>
  )
}

export default Navbar