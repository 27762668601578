import React, { useState } from 'react'
import './notificationStyles.scss'
import announcements from './announcements'
import slide4 from '../images/flyer09Nov2024.jpg';

const Notifications = () => {
    const [toggled, setToggled] = useState()
  return (
    <div className='notification-cover'>
        
        <div className='public-notifications'>
            <h4>Public Notifications</h4>
        </div>

        <div className='notifications'>
            <div className='centre'>
                <div className='left'>
                    {announcements.map((item) =>(
                        <button onClick={()=>setToggled(item.id)}>
                            <div className='titles' key={item.id}>
                                <p>Date: {item.date}</p>
                                <p>{item.title}</p>
                                <p>From: {item.from}</p>
                            </div>
                        </button>
                        
                    ))}
                </div>
                <div className='right'>
                    {announcements.map((item) =>{
                        return(
                            <>
                                {toggled === item.id ? (
                                    <div className='whole-message'>
                                        <h5>Date: {item.date} | From: {item.from}</h5>
                                        <h4>{item.title}</h4>
                                        <p>{item.message}</p>

                                        <p>{item.image !== 'available'?<><img src={slide4} width="500px" height="500px"/></>:''}</p>
                                        
                                    </div>
                                ): null}
                                
                            </>
                        )
                    })}
                </div>
            </div>
            
        </div>
        
    
    </div>
  )
}

export default Notifications