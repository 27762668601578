import React from 'react'
import './contactBarStyles.scss'
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';

const ContactsBar = () => {
  return (
    <div className='contacts-bar-cover'>
        <div className='cover'>
            <div className='facebook'><FacebookIcon style={{color:'#fff'}}/> Mutemwa Leprosy Catholic & Care Centre | </div>
            <div className='contacts'><PhoneIcon style={{color:'#fff'}}/><WhatsAppIcon style={{color:'#fff'}}/> +263 713 922 311 / +263 773 440 730</div>
        </div>
        
    </div>
  )
}

export default ContactsBar