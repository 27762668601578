import React from 'react'
import './aboutUsStyles.scss'
import MutemwaLogo from '../images/mutemwaLogo.png'
import oldImage1 from '../images/54995306.jpg'
import oldImage2 from '../images/54995318.jpg'
import oldImage3 from '../images/54995332.jpg'
import oldImage4 from '../images/54995338.jpg'
import oldImage5 from '../images/54995347.jpg'
import oldImage6 from '../images/54995363.jpg'
import oldImage7 from '../images/54995383.jpg'
import oldImage8 from '../images/54995392.jpg'
import oldImage9 from '../images/jb-at-mutemwa_orig.jpg'
import oldImage10 from '../images/JohnBradburne.jpg'
import oldImage11 from '../images/johnBradburne1.webp'
import oldImage12 from '../images/JohnBradburne2.webp'
import oldImage13 from '../images/JohnBradburne3.jpeg'

const Aboutus = () => {
  return (
    <div className='about-us-cover'>
        
        <div className='about-us'>
          <div className='logo-area'>
            <img src={MutemwaLogo}/>
          </div>
          <div className='history-area'>
            <div className='heading'>
              <h4>History and Background of Mutemwa</h4>
              <p>
              Mutemwa Leprosy Settlement was established in 1937 as a Leprosium. At the peak of its activities it held a thousand Leprosy patients. It was however officially closed in 1962 when treatment was introduced. The advent of new leprosy drugs meant that patients could then be treated in their own homes with less danger of infecting others. A decision was made by the authorities in 1962 to reintegrate all the patients within their families. Around 200 patients were left, mainly immigrants from neighboring countries who had nowhere to go or were rejected by their families. 
              </p>

              <div className='image-cover'>
                <div className='first-card'>
                  <img src={oldImage1}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage2}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage3}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage4}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage5}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage6}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage7}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage9}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage10}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage11}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage12}/>
                </div>
                <div className='first-card'>
                  <img src={oldImage13}/>
                </div>
              </div>
              <p>
              In 1969 John Randal Bradburne a Third Order Franciscan, mystic, poet and friend of Lepers moved in as the warden of the Settlement and gave unconditional loving care to them. He died on the 5th of September 1979 and the place has now become a Pilgrim Centre through John's extraordinary example.  
              </p>
              <p>
              In 1980, the late first lady of Zimbabwe Mother Sally Mugabe got wind of the plight of the Patients at Mutemwa and touched by their situation, mobilized resources and built them respectable accommodation.
              </p>
              <p>Currently, Mutemwa Leprosy and Care Centre hold an average of 33 Residents. Of the 34 patients 18 are Post Leprosy patients, 12 physically challenged and 3 Destitute. </p>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Aboutus 